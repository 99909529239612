export const routes = [
    // 团队
    {
        path: '/team',
        name: '团队',
        component: () => import('@/views/team/index.vue'),

    },
    {
        name: 'team',
        path: "/team/:team_id(\\d+)",
        component: () => import('@/views/api/team/index.vue'),
        redirect: '/team/:team_id(\\d+)/home',
        meta:{title:'团队管理',login:true},
        props:true,
        children: [
            { name: 'TeamHome',meta:{title:'首页'},path: 'home',component: () => import('@/views/api/team/c/TeamHome.vue') },
            { name: 'TeamCourse',meta:{title:'课程'},path: 'course',component: () => import('@/views/api/team/c/courseList.vue')},
            { name: 'TeamQuestList',meta:{title:'题单'},path: 'quesList',component: () => import('@/views/api/team/c/QuestList.vue') },
            { name: 'TeamQuestion',meta:{title:'题目'},path: 'question',component: () => import('@/views/api/team/c/QuestionList.vue') },
            { name: 'TeamUser',meta:{title:'成员'},path: 'user',component: () => import('@/views/api/team/c/UserForm.vue') },
            // { name: 'TeamData',meta:{title:'数据'},path: 'data',component: () => import('@/views/api/team/c/dataList.vue'),children: [
            //         { name: 'TeamDataMember',meta:{title:'成员排行榜'},path: 'member',component: () => import('@/views/api/team/c/data/member.vue') },
            //         { name: 'TeamDataQuestion',meta:{title:'题目排行榜'},path: 'question',component: () => import('@/views/api/team/c/data/question.vue')},
            //         { name: 'TeamDataMemData',meta:{title:'成员数据统计'},path: 'memData',component: () => import('@/views/api/team/c/data/memData.vue') },
            //         { name: 'TeamDataMemQuestion',meta:{title:'成员题目记录'},path: 'memQuestion',component: () => import('@/views/api/team/c/data/memQuestion.vue') },
            //         { name: 'TeamDataMemMath',meta:{title:'成员比赛记录'},path: 'memMath',component: () => import('@/views/api/team/c/data/memMath.vue') },
            //         { name: 'TeamDataMemQuestList',meta:{title:'成员题单记录'},path: 'memQuestList',component: () => import('@/views/api/team/c/data/memQuestList.vue') },
            //     ],
            // },
            // { name: 'TeamExam',meta:{title:'考试'},path: 'exam',component: () => import('@/views/api/team/c/examList.vue') },
            // { name: 'TeamMatch',meta:{title:'比赛'},path: 'match',component: () => import('@/views/api/team/c/matchList.vue') },
            // { name: 'TeamTask',meta:{title:'作业'},path: 'task',component: () => import('@/views/api/team/c/taskList.vue') },
            { name: 'TeamNotice',meta:{title:'通知'},path: 'notice',component: () => import('@/views/api/team/c/NoticeList.vue') },
            { name: 'TeamFile',meta:{title:'附件'},path: 'file',component: () => import('@/views/api/team/c/FileData.vue') },
            // { name: 'teamAttendance',meta:{title:'出勤'},path: 'attendance',component: () => import('@/views/api/team/c/attendanceList.vue')
            // ,children:[
            //     { name: 'teachingAttendanceList',meta:{title:'出勤列表'},path: 'list',component: () => import('@/views/api/team/c/attendanceList.vue') },
            //     // { name: 'teachingTaskCensus',meta:{title:'作业统计详情'},path: 'census/:other_id(\\d+)?',component: () => import('@/views/api/teaching/questOther/census.vue') },
            //     // { name: 'teachingTaskQuestion',meta:{title:'作业题目管理'},path: ':other_id(\\d+)/question',component: () => import('@/views/api/teaching/questOther/chooseList.vue') },
            // ]
            // },
            // { name: 'teamTotal',meta:{title:'统计'},path: 'total',component: () => import('@/views/api/team/c/stuList.vue')
            //     ,children:[
            //         { name: 'teamTotalList',meta:{title:'学生'},path: 'list',component: () => import('@/views/api/team/c/stuList.vue') },
            //         // { name: 'teamTotalInfo',meta:{title:'班课详情'},path: '/team/totalDetail',component: () => import('@/views/api/team/c/totalDetail.vue') },
            //     ]
            // },

            { name: 'TeamQuestionCensus',meta:{title:'题目统计详情',hidden:true},path: 'question/:question_id(N\\d+|P\\d+|\\d+)/census',component: () => import('@/views/api/teaching/question/census.vue') },
            { name: 'TeamQuestionInfo',meta:{title:'题目详情',hidden:true},path: 'question/:question_id(N\\d+|P\\d+|\\d+)/:tab(desc|analysis)?',component: () => import('@/views/api/teaching/question/detail.vue'),children: [
                    { name: 'TeamQuestionInfoComment',meta:{title:'题目讨论'},path: 'comment',component: {
                            props:['question_id'],
                            template:'<comment type="question" :where="{id:question_id||$attrs.question_id}"></comment>',
                            components: {
                                comment: () => import('@/views/api/com/comment.vue'),
                            }
                        }
                    },
                    { name: 'TeamQuestionInfoRecord',meta:{title:'提交记录'},path: 'record',component: () => import('@/views/api/teaching/question/c/record.vue') },
                ],
            },


            { name: 'TeamQuestListInfo',meta:{title:'题单详情',hidden:true},props:true,path: 'quesList/:list_id(\\d+)', component: () => import('@/views/api/teaching/questList/detail.vue')  },
        ],
    },

    // 新学习进度
    { name: 'TeamCourseStudySpeed',path: "/team/course/study/:study_user_id(\\d+)?/speed",meta:{title:'学习进度',login:true},component: () => import('@/views/me/speed.vue'),},



    { name: 'TeamInfo',meta:{title:'团队详情',login:true},path: '/team/:team_id(\\d+)/detail',component: () => import('@/views/api/team/detail.vue') },
    { name: 'teamTotalInfo',meta:{title:'班课详情',login:true},path: '/team/totalDetail',component: () => import('@/views/api/team/totalDetail.vue') },
    { name: 'TeamQuestionCreate',meta:{title:'创建题目',login:true},path: '/team/:team_id(\\d+)/question/save', component: {
            template:`<div>
            <el-card style="margin-top: 20px;">
              <div slot="header" class="clearfix" style="display: flex;">
                <el-image :src="headImg" style="width: 24px; height: 24px"></el-image>
                <span style="color: #00957e">创建题目</span>
              </div>
              <question-save></question-save>
            </el-card>
            </div>`,
            data(){
                return {
                    headImg:require('@/assets/images/problemList/标签@2x.png')
                };
            },
            components:{
                questionSave:()=> import('@/views/api/teaching/question/save.vue')
            }
        }
    },
    { name: 'TeamUserInfoData',meta:{title:'成员详情',login:true},path: '/team/:team_id(\\d+)/user/:user_id(\\d+)/data', component: () => import('@/views/api/team/c/user/data.vue')  },
    {
        name: '',
        path: "/establishTeamInfo",
        redirect: '/team/teamEstablish',
        component: () => import('@/views/team/establishTeamInfo.vue'),
        children: [
            { name: 'TeamCreate', path: "/team/teamEstablish",meta:{title:'团队信息',login:true}, component: () => import('@/views/team/TeamEstablish.vue'), },
            { name: 'teamEdit', path: "/team/:team_id(\\d+)/teamEstablish",meta:{title:'编辑团队'}, component: () => import('@/views/team/TeamEstablish.vue'), },
            { name: '我的成绩', path: "/team/personal", component: () => import('@/views/team/Personal.vue'), },
        ]
    },
]